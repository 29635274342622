.StartLocation {
    display: flex;
    align-items: center;
    flex-flow: column;
    flex-wrap: wrap;
    font-family: 'narpassword00000_fixed.normal';
    text-transform: uppercase;
}

.StartLocation h3 {
    text-align: center;
}

.StartLocation h4 {
    margin: 2px 0px;
    font-weight: lighter;
    color: #aaaaaa;
}

.StartLocation h4.startLocation {
    color: #009400;
}

.StartLocation h4.invalidStartLocation {
    color: #aa0000;
}
