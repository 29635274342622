.GameState {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
}

.GameState .column {
    flex-grow: 1;
}

.GameState .column#column1 {
    max-width: 400px;
}

.GameState .column#column2 {
    max-width: 450px;
}

.GameState .column#column3 {
}

.GameState .row {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
}

.GameState .row div {
    flex-basis: 33%;;
}
