.PowerUpList {
    font-family: 'narpassword00000_fixed.normal';
    text-transform: uppercase;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
}

.PowerUpList h3 {
    flex-basis: 100%;
}

.PowerUpList .icons {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.PowerUpList .images {
    flex-basis: 100%;
}

.PowerUpList img {
    width: 48px;
}

.PowerUpList-available {
    filter: opacity(40%) grayscale(40%);
}

.PowerUpList-inaccessible {
    filter: grayscale(100%) opacity(100%);
}
