.Items {
    font-family: 'narpassword00000_fixed.normal';
    text-transform: uppercase;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
}

.Items .itemTypes {
    display: flex;
    flex-direction: row;
}

.Items .itemList {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Items h3,h4 {
    flex-basis: 100%;
}

.Items .itemList .Items-list {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
}

.Items .itemList .Items-list .item {
    flex-shrink: 1;
    text-align: center;
    flex-basis: 55px;
}

.Items .itemList .Items-list .item img {
    width: 48px;
}

.Items .item-missing {
    filter: grayscale(100%) opacity(60%);
}
