.PasswordEntry {
    display: flex;
    flex-basis: 100%;
    flex-direction: row;
    align-items: center;
    padding: 20px;
}

.PasswordEntry input[type=text] {
    border-radius: 4px;
    margin-right: 10px;
}

.PasswordEntry input[type=text].good {
    border: 5px solid #009400;
}

.PasswordEntry input[type=text].bad {
    border: 5px solid #aa0000;
}

.PasswordEntry input {
    text-align: center;
    font-size: 18px;
    width: 450px;
    height: 40px;
    font-family: 'narpassword00000_fixed.normal';
}
