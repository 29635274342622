.Doors {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.Doors h3,h4 {
    text-transform: uppercase;
    font-family: 'narpassword00000_fixed.normal';
    flex-basis: 100%;
    text-align: center;
}

.Doors .doorsForArea {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-basis: 33%;
    max-width: 120px;
}

.Doors .doorsForArea .doorList {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
