body {
    background-color: black;
    color: white;
}

h3 {
    margin: 30px 0 10px 0;
}

h4 {
    margin: 30px 0 10px 0;
}

.App {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.App p {
    font-family: 'narpassword00000_fixed.normal';
    text-transform: uppercase;
    text-align: center;
}

.App-header {
    padding: 20px;
    font-family: 'narpassword00000_fixed.normal';
    display: flex;
    flex-direction: row;
    align-items: flex-end;
}

.App-header .headerText {
    text-align: center;
    min-width: 375px;
}

.App-header .headerText h2 {
    text-transform: uppercase;
    font-family: 'narpassword00000_fixed.normal';
}

.App-header .headerText h3 {
    text-transform: uppercase;
    font-family: 'narpassword00000_fixed.normal';
    margin: 0px;
}

.App-header img {
    margin: 0px 15px;
    height: 80px;
}

.App .footer {
    font-family: 'narpassword00000_fixed.normal';
    text-transform: uppercase;
    text-align: center;
    padding-top: 20px;
    font-size: 12px;
}

.App .footer a {
    color: #62ACFF;
}

.flipX {
    -moz-transform: scaleX(-1);
    -o-transform: scaleX(-1);
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    filter: FlipH;
    -ms-filter: "FlipH";
}
