.MissileCount {
    font-family: 'narpassword00000_fixed.normal';
    text-transform: uppercase;
    display: block;
}

.MissileCount-container {
    display: flex;
    align-items: center;
    flex-flow: column;
}

.MissileCount h3 {
    text-align: center;
}

.MissileCount-icon {
    float: left;
    margin-bottom: 6px;
}

.MissileCount-count {
    float: left;
    font-size: 18px;
    font-family: 'narpassword00000_fixed.normal';
    width: 3em;
    text-align: center;
    border: 5px solid #009400;
}
